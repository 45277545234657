import React, { useEffect, useRef } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import media from "src/styles/media"

gsap.registerPlugin(ScrollTrigger)

type Props = {
  image: string
  border: string
}

const HorizontalScroller: React.FC<Props> = ({ image, border }) => {
  const ref: any = useRef()
  useEffect(() => {
    if (window.innerWidth > 1024) {
      gsap.to(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          scrub: 0.2,
        },
        x: "-120vw",
      })
    } else {
      gsap.to(ref.current, {
        scrollTrigger: {
          trigger: ref.current,
          scrub: true,
        },
        x: "-80vw",
      })
    }
  }, [])
  return (
    <Wrapper border={border}>
      <Slider ref={ref}>
        <Image src={image} alt="" />
      </Slider>
    </Wrapper>
  )
}

const Wrapper = styled.div<{ border: string }>`
  width: 100vw;
  max-width: 100%;
  height: min-content;
  overflow: hidden;
  border-top: solid 1px ${props => props.border};
  border-bottom: solid 1px ${props => props.border};
`
const Slider = styled.div`
  height: 20.4vw;
  width: 1000vw;
  font-family: "Monument Extended";
  font-size: 13.19vw;
  text-transform: uppercase;
  line-height: 1.2em;
  letter-spacing: -0.0037828947368421053em;
  ${media.tablet} {
    height: 20vw;
  }
`
const Image = styled.img`
  height: 100%;
`

export default HorizontalScroller
