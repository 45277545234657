import React from "react"
import HorizontalScroller from "src/components/HorizontalScroller"

import inTheWild from "svg/inTheWild.svg"
import Colors from "src/styles/Colors"

const InTheWild: React.FC<{}> = () => {
  return <HorizontalScroller image={inTheWild} border={Colors.purple} />
}

export default InTheWild
