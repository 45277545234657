import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

import { Label1, Paragraph1, Title1, Title3 } from "src/components/Text"
import Colors from "src/styles/Colors"

import { FluidImage } from "src/Types"

import { BoxOutlineButton } from "src/components/Buttons"
import { ReactComponent as ArrowSVG } from "svg/arrowRight.svg"
import media from "src/styles/media"

type Props = {
  data: {
    astro1: FluidImage
    lineGrid: FluidImage
    builtOnBlockchain: FluidImage
  }
}

const Section2: React.FC<Props> = ({ data }) => {
  return (
    <Wrapper>
      <Title className="creators__section2-item">Gaming</Title>
      <Label className="creators__section2-item">
        and other virtual places
      </Label>
      <Line className="creators__section2-item" />
      <Details>
        <Detail className="creators__section2-item">
          <TitleSecondary>Digital goods need a home!</TitleSecondary>
          <Paragraph>
            People want places to flex their digital goods in virtual places.
            Start supporting digital goods today! htw, we know it's more
            complicated than dropping in an SDK. Let us walk you through it.
          </Paragraph>
        </Detail>
        <Detail className="creators__section2-item">
          <TitleSecondary>Cosmetics Only?</TitleSecondary>
          <Paragraph>
            Let's keep this simple. Let players express themselves in their
            truest form. Enable creator and brand content to live in your
            worlds.{" "}
          </Paragraph>
        </Detail>
        <Detail className="creators__section2-item">
          <TitleSecondary>Increase your GDP!</TitleSecondary>
          <Paragraph>
            Upload an asset standard, and let creators offload some of that
            creative work. Charge a small tariff anytime an item gets sold.
          </Paragraph>
        </Detail>
      </Details>
      <OpenEconomy className="creators__section2-item">
        Ready to start your open economy?
      </OpenEconomy>
      <Button color="black" arrow>
        let’s get started
        <Arrow />
      </Button>
      <Grid>
        <Img fluid={data.lineGrid.childImageSharp.fluid} />
      </Grid>
      <Astro>
        <Img fluid={data.astro1.childImageSharp.fluid} />
      </Astro>
      <Sticker>
        <Img fluid={data.builtOnBlockchain.childImageSharp.fluid} />
      </Sticker>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 69.2vw;
  width: 72.22%;
  margin-left: 2.5vw;
  position: relative;
  overflow: hidden;
  ${media.tablet} {
    height: 350.2vw;
    margin-left: 4.8vw;
    width: 90.3vw;
  }
`
const Title = styled(Title1)`
  color: ${Colors.darkGrey};
  ${media.tablet} {
    margin-left: 25.4vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.darkGrey};
  line-height: 1.08em;
  letter-spacing: -0.02875em;
  font-size: 1.7vw;

  ${media.tablet} {
    margin-left: 25.4vw;
    font-size: 4.8vw;
  }
`
const Line = styled.hr`
  width: 65.3vw;
  height: 2px;
  background: ${Colors.darkGrey};
  margin: 1.8vw 0 5.2vw;
  ${media.tablet} {
    width: 100%;
    height: 1px;
    margin: 7.2vw 0 18.4vw;
  }
`
const Details = styled.div`
  width: 65.3vw;
  display: grid;
  grid-template-columns: repeat(2, 29.3vw);
  grid-column-gap: 6.5vw;
  ${media.tablet} {
    width: 100%;
    display: block;
  }
`
const Detail = styled.div`
  height: 15.2vw;
  ${media.tablet} {
    height: unset;
    margin-bottom: 12.1vw;
  }
`
const TitleSecondary = styled(Title3)`
  font-family: "Neue Machina";
  font-weight: normal;
  ${media.tablet} {
    // width: 56.3vw;
  }
`
const Paragraph = styled(Paragraph1)`
  margin-left: 3.3vw;
  color: ${Colors.charcoal};
  margin-top: 0.7vw;
  ${media.tablet} {
    margin-left: 12.3vw;
    margin-top: 2.4vw;
  }
`
const OpenEconomy = styled(Title3)`
  margin-top: 0.2vw;
  font-weight: normal;
  ${media.tablet} {
    margin-top: 65.2vw;
  }
`
const Button = styled(BoxOutlineButton)`
  width: 16vw;
  background: ${Colors.yellow};
  margin-top: 4.2vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 10.9vw;
    justify-content: center;
  }
`
const Arrow = styled(ArrowSVG)`
  width: 1.7vw;
  ${media.tablet} {
    width: 6vw;
    margin-left: 3vw;
  }
`
const Grid = styled.div`
  position: absolute;
  width: 22.4vw;
  top: 28vw;
  left: 37vw;
  filter: drop-shadow(4px 4px 0px #000000);
  ${media.tablet} {
    width: 59.6vw;
    left: 18.1vw;
    top: 193vw;
  }
`
const Astro = styled.div`
  width: 42.5vw;
  position: absolute;
  top: 20vw;
  left: 17vw;
  ${media.tablet} {
    width: 116.9vw;
    top: 170.4vw;
    left: -37vw;
  }
`
const Sticker = styled.div`
  width: 12.5vw;
  position: absolute;
  top: 32.8vw;
  left: 55vw;
  ${media.tablet} {
    width: 22.7vw;
    top: 215vw;
    left: 64.7vw;
  }
`
export default Section2
