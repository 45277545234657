import React, { useRef, useEffect, useState } from "react"
import styled from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Img from "gatsby-image"
import delay from "lodash.delay"

import pointer from "svg/pointer.svg"

import { Label1, Paragraph2, Title2 } from "src/components/Text"
import Colors from "src/styles/Colors"

import { FluidImage } from "src/Types"
import media from "src/styles/media"

type Props = {
  data: {
    customAir: FluidImage
    customEmote: FluidImage
    arwing: FluidImage
    dotGrid: FluidImage
    interfacePanel: FluidImage
    trade: FluidImage
    fruit: FluidImage
    pizza: FluidImage
    suhDude: FluidImage
    greenCoin: FluidImage
    appCircle: FluidImage
  }
}

const UserWallet: React.FC<Props> = ({ data }) => {
  const [step1Images, setStep1Images] = useState(
    gsap.timeline({ paused: true })
  )
  const [step2Images, setStep2Images] = useState(
    gsap.timeline({ paused: true })
  )
  const [step3Images, setStep3Images] = useState(
    gsap.timeline({ paused: true })
  )
  const [step4Images, setStep4Images] = useState(
    gsap.timeline({ paused: true })
  )

  const ref: any = useRef()
  const firstAnimationComplete = useRef(false)
  const interval = useRef(null)
  const step = useRef(0)
  const isAnimating = useRef(false)

  const step0 = () => {
    if (typeof window === "undefined") {
      return
    }
    if (isAnimating.current) {
      return
    }
    isAnimating.current = true

    step2Images.reverse()
    step3Images.reverse()
    step4Images.reverse()
    delay(() => {
      step1Images.play()
    }, 700)
    const tl = gsap.timeline()
    tl.to(".wallet__step1-text", { opacity: 1, duration: 0.3 }, 0)

    tl.to(
      ".wallet__step2-text, .wallet__step3-text, .wallet__step4-text",
      { opacity: 0.5, duration: 0.3 },
      0
    )
    tl.to(
      ".wallet__indicator1",
      {
        width: window.innerWidth < 1024 ? "4.8vw" : "1.7vw",
        marginRight: "1vw",
        duration: 0.3,
      },
      0
    )
    tl.to(
      ".wallet__indicator2, .wallet__indicator3, .wallet__indicator4",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
    tl.to(".wallet__step1-paragraph", { opacity: 1, y: 0, duration: 0.3 }, 0.5)
    tl.to(
      ".wallet__step2-paragraph, .wallet__step3-paragraph, .wallet__step4-paragraph",
      { opacity: 0, y: 100, duration: 0 },
      0.5
    )

    delay(() => {
      isAnimating.current = false
    }, 1400)
  }
  const step1 = () => {
    if (typeof window === "undefined") {
      return
    }
    if (isAnimating.current) {
      return
    }
    isAnimating.current = true

    step1Images.reverse()
    step3Images.reverse()
    step4Images.reverse()
    delay(() => {
      step2Images.play()
    }, 700)
    const tl = gsap.timeline()
    tl.to(".wallet__step2-text", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".wallet__step1-text, .wallet__step3-text, .wallet__step4-text",
      { opacity: 0.5, duration: 0.3 },
      0
    )
    tl.to(
      ".wallet__indicator2",
      {
        width: window.innerWidth < 1024 ? "4.8vw" : "1.7vw",
        marginRight: "1vw",
        duration: 0.3,
      },
      0
    )
    tl.to(
      ".wallet__indicator1, .wallet__indicator3, .wallet__indicator4",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )

    tl.to(".wallet__step2-paragraph", { opacity: 1, y: 0, duration: 0.3 }, 0.5)
    tl.to(
      ".wallet__step1-paragraph, .wallet__step3-paragraph, .wallet__step4-paragraph",
      { opacity: 0, y: 100, duration: 0 },
      0.5
    )
    delay(() => {
      isAnimating.current = false
    }, 1400)
  }
  const step2 = () => {
    if (typeof window === "undefined") {
      return
    }
    if (isAnimating.current) {
      return
    }
    isAnimating.current = true

    step2Images.reverse()
    step1Images.reverse()
    step4Images.reverse()
    delay(() => {
      step3Images.play()
    }, 700)
    const tl = gsap.timeline()
    tl.to(".wallet__step3-text", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".wallet__step1-text, .wallet__step2-text, .wallet__step4-text",
      { opacity: 0.5, duration: 0.3 },
      0
    )
    tl.to(
      ".wallet__indicator3",
      {
        width: window.innerWidth < 1024 ? "4.8vw" : "1.7vw",
        marginRight: "1vw",
        duration: 0.3,
      },
      0
    )
    tl.to(
      ".wallet__indicator1, .wallet__indicator2, .wallet__indicator4",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
    tl.to(".wallet__step3-paragraph", { opacity: 1, y: 0, duration: 0.3 }, 0.5)
    tl.to(
      ".wallet__step1-paragraph, .wallet__step2-paragraph, .wallet__step4-paragraph",
      { opacity: 0, y: 100, duration: 0 },
      0.5
    )
    delay(() => {
      isAnimating.current = false
    }, 1400)
  }

  const step3 = () => {
    if (typeof window === "undefined") {
      return
    }
    if (isAnimating.current) {
      return
    }
    isAnimating.current = true

    step2Images.reverse()
    step3Images.reverse()
    step1Images.reverse()
    delay(() => {
      step4Images.play()
    }, 700)

    const tl = gsap.timeline()
    tl.to(".wallet__step4-text", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".wallet__step1-text, .wallet__step2-text, .wallet__step3-text",
      { opacity: 0.5, duration: 0.3 },
      0
    )
    tl.to(
      ".wallet__indicator4",
      {
        width: window.innerWidth < 1024 ? "4.8vw" : "1.7vw",
        marginRight: "1vw",
        duration: 0.3,
      },
      0
    )
    tl.to(
      ".wallet__indicator1, .wallet__indicator2, .wallet__indicator3",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
    tl.to(".wallet__step4-paragraph", { opacity: 1, y: 0, duration: 0.3 }, 0.5)
    tl.to(
      ".wallet__step1-paragraph, .wallet__step2-paragraph, .wallet__step3-paragraph",
      { opacity: 0, y: 100, duration: 0 },
      0.5
    )
    delay(() => {
      isAnimating.current = false
    }, 1400)
  }

  const cycle = () => {
    switch (step.current) {
      case 0:
        step1()
        step.current = 1
        break
      case 1:
        step2()
        step.current = 2
        break
      case 2:
        step3()
        step.current = 3
        break
      case 3:
        step0()
        step.current = 0
        break
      default:
        break
    }
  }

  const initialAnimation = () => {
    if (typeof window === "undefined") {
      return
    }
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
        interval.current = setInterval(cycle, 3000)
      },
    })
    tl.to(".wallet__step1-text", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".wallet__step2-text, .wallet__step3-text, .wallet__step4-text",
      { opacity: 0.5, duration: 0.3 },
      0
    )
    tl.to(
      ".wallet__indicator1",
      {
        width: window.innerWidth < 1024 ? "4.8vw" : "1.7vw",
        marginRight: "1vw",
        duration: 0.3,
      },
      0
    )
    tl.to(
      ".wallet__indicator2, .wallet__indicator3, .wallet__indicator4",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
    tl.to(".wallet__step", { opacity: 1, x: 0, duration: 0.3, stagger: 0.1 }, 0)
    tl.to(".wallet__step1-paragraph", { opacity: 1, y: 0, duration: 0.3 }, 0.5)
    tl.to(
      ".wallet__step2-paragraph, .wallet__step3-paragraph, .wallet__step4-paragraph",
      { opacity: 0, y: 100, duration: 0.3 },
      0.5
    )
    delay(() => {
      step1Images.play()
    }, 700)
  }

  useEffect(() => {
    ScrollTrigger.create({
      trigger: ref.current,
      start: "top 20%",
      onToggle: ({}) => {
        if (firstAnimationComplete.current) {
          return
        }
        initialAnimation()
        firstAnimationComplete.current = true
      },
    })
    step1Images.to(".wallet__step1-image", {
      opacity: 1,
      x: 0,
      y: 0,
      duration: 0.5,
      ease: "power4.out",
    })
    step2Images.to(".wallet__step2-image", {
      opacity: 1,
      x: 0,
      y: 0,
      rotate: 0,
      duration: 0.5,
      ease: "power4.out",
    })
    step3Images.to(".wallet__step3-image", {
      opacity: 1,
      scale: 1,
      duration: 0.5,
      stagger: 0.1,
      ease: "power4.out",
    })
    step4Images.to(".wallet__step4-image", {
      opacity: 1,
      rotate: 0,
      scale: 1,
      duration: 0.5,
      ease: "power4.out",
    })
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <Wrapper ref={ref} id="#user">
      <LabelWrapper>
        <Label>the user wallet</Label>
      </LabelWrapper>
      <Title>A place to store all your digital things. That you own.</Title>
      <StepList>
        <Step className="wallet__step">
          <StepButton
            onClick={() => {
              step0()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="wallet__indicator1" />{" "}
            <StepText className="wallet__step1-text">Own Things</StepText>
          </StepButton>
        </Step>
        <Step className="wallet__step">
          <StepButton
            onClick={() => {
              step1()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="wallet__indicator2" />{" "}
            <StepText className="wallet__step2-text">Trade Things</StepText>
          </StepButton>
        </Step>
        <Step className="wallet__step">
          <StepButton
            onClick={() => {
              step2()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="wallet__indicator3" />{" "}
            <StepText className="wallet__step3-text">Sell Things</StepText>
          </StepButton>
        </Step>
        <Step className="wallet__step">
          <StepButton
            onClick={() => {
              step3()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="wallet__indicator4" />{" "}
            <StepText className="wallet__step4-text">Connect Apps</StepText>
          </StepButton>
        </Step>
      </StepList>
      <Paragraph className="wallet__step1-paragraph">
        All NFTs are certifiably unique and ownable.
      </Paragraph>
      <Paragraph className="wallet__step2-paragraph">
        Ever trade a fruit cup for a slice of pizza? no? Well if those were
        digital, you could trade them ok.
      </Paragraph>
      <Paragraph className="wallet__step3-paragraph">
        Ya don't own something unless you can sell it! You can sell your NFTs
        through any supported marketplace.
      </Paragraph>
      <Paragraph className="wallet__step4-paragraph">
        Still need content here to finish this section. But yeah, you can
        connect apps.
      </Paragraph>

      <Grid>
        <Img fluid={data.dotGrid.childImageSharp.fluid} />
      </Grid>
      <Panel>
        <Img fluid={data.interfacePanel.childImageSharp.fluid} />
      </Panel>
      <Arwing className="wallet__step1-image">
        <Img fluid={data.arwing.childImageSharp.fluid} />
      </Arwing>
      <Emote className="wallet__step1-image">
        <Img fluid={data.customEmote.childImageSharp.fluid} />
      </Emote>
      <Air className="wallet__step1-image">
        <Img fluid={data.customAir.childImageSharp.fluid} />
      </Air>
      <Trade className="wallet__step2-image">
        <Img fluid={data.trade.childImageSharp.fluid} />
      </Trade>
      <Pizza className="wallet__step2-image">
        <Img fluid={data.pizza.childImageSharp.fluid} />
      </Pizza>
      <Fruit className="wallet__step2-image">
        <Img fluid={data.fruit.childImageSharp.fluid} />
      </Fruit>
      <SuhDude className="wallet__step3-image">
        <Img fluid={data.suhDude.childImageSharp.fluid} />
      </SuhDude>
      <Coin1 className="wallet__step3-image">
        <Img fluid={data.greenCoin.childImageSharp.fluid} />
      </Coin1>
      <Coin2 className="wallet__step3-image">
        <Img fluid={data.greenCoin.childImageSharp.fluid} />
      </Coin2>
      <Rect1 className="wallet__step3-image">BUY NOW</Rect1>
      <Rect2 className="wallet__step3-image">MAKE OFFER</Rect2>
      <AppCircle className="wallet__step4-image">
        <Img fluid={data.appCircle.childImageSharp.fluid} />
      </AppCircle>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 65.3vw;
  padding: 2.8vw 6.9vw;
  position: relative;
  overflow: hidden;
  max-width: 100%;
  ${media.tablet} {
    height: 276.8vw;
    padding: 10.9vw 4.8vw;
  }
`
const LabelWrapper = styled.div`
  width: 13.5vw;
  height: 3.2vw;
  border: solid 1px ${Colors.green};
  border-radius: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    height: 8vw;
    width: 32.1vw;
    border-radius: 24.1vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.green};
  font-size: 2vw;
  line-height: 1.0666666666666667em;
  letter-spacing: -0.043125em;
  text-align: center;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`
const Title = styled(Title2)`
  color: white;
  width: 42.2vw;
  margin-top: 9.7vw;
  font-weight: normal;
  ${media.tablet} {
    width: 100%;
  }
`
const StepList = styled.ul`
  width: 17vw;
  margin-top: 6vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 15vw;
  }
`
const Step = styled.li`
  height: 2.7vw;
  margin-bottom: 1.7vw;
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
  ${media.tablet} {
    height: 7.5vw;
    margin-bottom: 6.7vw;
  }
`
const StepButton = styled.button`
  background: transparent;
  border: none;
  cursor: url(${pointer}), auto;
  display: flex;
  align-items: center;
  padding: 0;
`
const StepText = styled.span`
  font-size: 2vw;
  color: white;
  opacity: 0.5;
  ${media.tablet} {
    font-size: 5.8vw;
  }
`
const Indicator = styled.div`
  width: 0;
  height: 3px;
  background: ${Colors.green};
  // margin-right: 1vw;
  ${media.tablet} {
    width: 4.8vw;
  }
`
const Paragraph = styled(Paragraph2)`
  position: absolute;
  top: 33.7vw;
  left: 33vw;
  width: 22.2vw;
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  ${media.tablet} {
    width: 90.3vw;
    top: 156vw;
    left: 4.8vw;
    z-index: 1;
  }
`
const Grid = styled.div`
  position: absolute;
  width: 25vw;
  top: 7.4vw;
  left: 75.2vw;
  ${media.tablet} {
    width: 87.2vw;
    top: 101.4vw;
    left: 61.8vw;
  }
`
const Panel = styled.div`
  position: absolute;
  width: 19.5vw;
  top: 15.4vw;
  left: 80.5vw;
  ${media.tablet} {
    width: 40.8vw;
    top: 184.3vw;
    left: 64.7vw;
  }
`
const Arwing = styled.div`
  position: absolute;
  width: 19vw;
  top: 25vw;
  left: 93.2vw;
  opacity: 0;
  transform: translate3d(-250px, 0, 0);
  ${media.tablet} {
    width: 34.8vw;
    top: 201.7vw;
    left: 87.9vw;
    transform: translate3d(-75px, 0, 0);
  }
`
const Emote = styled.div`
  position: absolute;
  width: 19vw;
  top: 34.1vw;
  left: 77.8vw;
  opacity: 0;
  transform: translate3d(-50px, -150px, 0);
  ${media.tablet} {
    width: 34.8vw;
    top: 218.1vw;
    left: 59.9vw;
    transform: translate3d(-50px, -150px, 0);
  }
`
const Air = styled.div`
  position: absolute;
  width: 29.1vw;
  top: 10.6vw;
  left: 53.4vw;
  opacity: 0;
  transform: translate3d(150px, 50px, 0);
  ${media.tablet} {
    width: 54.3vw;
    top: 175.3vw;
    left: 15.2vw;
    transform: translate3d(75px, 50px, 0);
  }
`
const Trade = styled.div`
  position: absolute;
  width: 30.5vw;
  top: 26.9vw;
  left: 61.5vw;
  opacity: 0;
  transform: rotate(180deg);
  ${media.tablet} {
    width: 55.6vw;
    top: 203.9vw;
    left: 30.2vw;
  }
`
const Pizza = styled.div`
  position: absolute;
  width: 28.3vw;
  top: 22.8vw;
  left: 70.3vw;
  opacity: 0;
  transform: translate3d(-200px, 200px, 0);
  ${media.tablet} {
    width: 51.7vw;
    top: 197.8vw;
    left: 46.1vw;
    transform: translate3d(-50px, 50px, 0);
  }
`
const Fruit = styled.div`
  position: absolute;
  width: 23.6vw;
  top: 41.2vw;
  left: 54.7vw;
  opacity: 0;
  transform: translate3d(200px, -200px, 0);
  ${media.tablet} {
    width: 43.2vw;
    top: 231vw;
    left: 17.9vw;
    transform: translate3d(50px, -50px, 0);
  }
`
const SuhDude = styled.div`
  position: absolute;
  width: 25.4vw;
  top: 25.4vw;
  left: 67.7vw;
  opacity: 0;
  transform: scale(0.3);
  ${media.tablet} {
    width: 48.1vw;
    top: 202.4vw;
    left: 39.4vw;
  }
`
const Coin1 = styled.div`
  position: absolute;
  width: 7.9vw;
  top: 50.1vw;
  left: 75.2vw;
  opacity: 0;
  transform: scale(0.3);
  ${media.tablet} {
    width: 15vw;
    top: 248.7vw;
    left: 53.6vw;
  }
`
const Coin2 = styled.div`
  position: absolute;
  width: 6.7vw;
  top: 37.7vw;
  left: 86.5vw;
  transform: rotate(18.48deg);
  opacity: 0;
  transform: scale(0.3);
  ${media.tablet} {
    width: 12.8vw;
    top: 225.3vw;
    left: 74.6vw;
  }
`
const Rect1 = styled.div`
  position: absolute;
  top: 25.1vw;
  left: 66.5vw;
  width: 8.3vw;
  height: 3.5vw;
  border: 1px solid ${Colors.green};
  background: ${Colors.charcoal};
  color: ${Colors.green};
  font-size: 1.2vw;
  text-align: center;
  line-height: 290%;
  opacity: 0;
  transform: scale(0.3);
  ${media.tablet} {
    height: 6.5vw;
    font-size: 2.4vw;
    width: 15.7vw;
    top: 201.9vw;
    left: 37.2vw;
  }
`
const Rect2 = styled(Rect1)`
  width: 9.9vw;
  top: 20.3vw;
  left: 61.6vw;
  opacity: 0;
  transform: scale(0.3);
  ${media.tablet} {
    width: 18.6vw;
    top: 192.7vw;
    left: 28vw;
  }
`
const AppCircle = styled.div`
  position: absolute;
  width: 29.7vw;
  top: 23.4vw;
  left: 59.9vw;
  opacity: 0;
  transform: scale(0.2) rotate(350deg);
  ${media.tablet} {
    top: 200vw;
    left: 20vw;
    width: 60vw;
  }
`
export default UserWallet
