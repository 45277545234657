import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import gsap from "gsap"
import delay from "lodash.delay"

import { Title1, Paragraph1, Label1 } from "components/Text"
import { BoxOutlineButton } from "components/Buttons"

import { ReactComponent as ArrowSVG } from "svg/arrowRight.svg"
import { ReactComponent as GridSVG } from "svg/grid.svg"

import Colors from "styles/Colors"

import { FluidImage } from "src/Types"
import media from "src/styles/media"

type Props = {
  data: {
    shoe: FluidImage
    heroTicket: FluidImage
    astronaut: FluidImage
  }
}

const Hero: React.FC<Props> = ({ data }) => {
  const shoe: any = useRef()
  const shoeOffset = useRef(-160)
  const card: any = useRef()
  const cardOffset = useRef(-120)
  const astro: any = useRef()
  const astroOffset = useRef(-80)
  const text: any = useRef()
  const isAnimating = useRef(true)
  const animation = useRef("shoe")

  const initialAnimationComplete = useRef(false)
  const tl = useRef(gsap.timeline())
  const wrapper: any = useRef()

  const onMouseMove = e => {
    if (!initialAnimationComplete.current) {
      return
    }
    parallaxIt(e, ".hero__grid", -30)
    parallaxIt(e, shoe.current, shoeOffset.current)
    parallaxIt(e, card.current, cardOffset.current)
    parallaxIt(e, astro.current, astroOffset.current)
  }

  const parallaxIt = (e, target, movement) => {
    const container = wrapper.current
    const relX = e.pageX - container.offsetLeft
    const relY = e.pageY - container.offsetTop

    gsap.to(target, {
      x:
        ((relX - container.offsetWidth / 2) / container.offsetWidth) * movement,
      y:
        ((relY - container.offsetHeight / 2) / container.offsetHeight) *
        movement,
    })
  }

  const cardAnimation = () => {
    if (isAnimating.current || animation.current === "card") {
      return
    }
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1024) {
        return
      }
    }
    animation.current = "card"
    isAnimating.current = true
    cardOffset.current = -160
    shoeOffset.current = -80
    astroOffset.current = -120
    gsap.to(shoe.current, {
      filter: "grayscale(1)",
      width: "35.6vw",
      rotate: 35.02,
      top: "28.5vw",
      left: "39.7vw",
      zIndex: 2,
    })
    gsap.to(card.current, {
      rotate: -31.5,
      filter: "grayscale(0)",
      width: "37vw",
      top: "3.4vw",
      left: "44.3vw",
      zIndex: 1,
    })
    gsap.to(astro.current, {
      top: "28.6vw",
      left: "73.1vw",
      filter: "grayscale(1)",
      width: "41.6vw",
      zIndex: 3,
      opacity: 0.8,
    })
    gsap.to(".hero__grid", {
      rotate: 10.97,
    })
    delay(() => {
      isAnimating.current = false
    }, 500)
  }
  const shoeAnimation = () => {
    cardOffset.current = -120
    shoeOffset.current = -160
    astroOffset.current = -80
    if (isAnimating.current || animation.current === "shoe") {
      return
    }
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1024) {
        return
      }
    }
    isAnimating.current = true
    animation.current = "shoe"
    gsap.to(shoe.current, {
      filter: "grayscale(0)",
      width: "60vw",
      rotate: 0,
      top: "0.8vw",
      left: "37.6vw",
      zIndex: 1,
    })
    gsap.to(card.current, {
      rotate: -3.69,
      filter: "grayscale(1)",
      width: "18.3vw",
      top: "6.4vw",
      left: "43.4vw",
      zIndex: 2,
    })
    gsap.to(astro.current, {
      top: "19.3vw",
      left: "74.1vw",
      filter: "grayscale(1)",
      width: "41.6vw",
      zIndex: 3,
      opacity: 0.8,
    })
    gsap.to(".hero__grid", {
      rotate: 8.2,
    })
    delay(() => {
      isAnimating.current = false
    }, 500)
  }

  const astroAnimation = () => {
    cardOffset.current = -120
    shoeOffset.current = -80
    astroOffset.current = -160
    if (isAnimating.current || animation.current === "astro") {
      return
    }
    if (typeof window !== "undefined") {
      if (window.innerWidth < 1024) {
        return
      }
    }
    isAnimating.current = true
    animation.current = "astro"
    gsap.to(shoe.current, {
      filter: "grayscale(1)",
      width: "29.9vw",
      rotate: 31.11,
      top: "0vw",
      left: "64.6vw",
      zIndex: 2,
    })
    gsap.to(card.current, {
      rotate: 27.37,
      filter: "grayscale(1)",
      width: "18.3vw",
      top: "5vw",
      left: "41vw",
      zIndex: 3,
    })
    gsap.to(astro.current, {
      top: "8.8vw",
      left: "27.8vw",
      filter: "grayscale(0)",
      width: "72.4vw",
      rotate: 17.85,
      zIndex: 1,
      opacity: 1,
    })
    gsap.to(".hero__grid", {
      rotate: 1.9,
    })
    delay(() => {
      isAnimating.current = false
    }, 500)
  }
  useEffect(() => {
    tl.current
      .to(
        shoe.current,
        {
          filter: "grayscale(0)",
          x: 0,
          y: 0,
          scale: 1,
          rotate: 0,
          ease: "power4.out",
          duration: 1.5,
        },
        1
      )
      .to(
        card.current,
        {
          x: 0,
          y: 0,
          scale: 1,
          rotate: -3.69,
          ease: "power4.out",
          duration: 1.5,
        },
        0.8
      )
      .to(
        astro.current,
        {
          x: 0,
          y: 0,
          scale: 1,
          rotate: -7.31,
          ease: "power4.out",
          duration: 1.5,
        },
        1.2
      )
      .to(".hero__grid", { duration: 0.5, opacity: 1, ease: "power4.out" }, 0.5)
      .to(text.current, { opacity: 1, y: 0, ease: "power4.out" }, 1.7)
    delay(() => {
      isAnimating.current = false
      initialAnimationComplete.current = true
    }, 2000)
  }, [])

  useEffect(() => {
    //set up anchor smooth scroll, this could probably be moved to layout.
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault()
        document.getElementById(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        })
      })
    })
  }, [])
  return (
    <Wrapper onMouseMove={onMouseMove} ref={wrapper}>
      <Grid className="hero__grid" />
      <TextWrapper ref={text}>
        <Title>Create, sell and own digital goods *</Title>
        <Paragraph>
          Bitski connects communities, creators and brands through unique,
          ownable digital content. This is how the metaverse begins.
        </Paragraph>
        <Buttons>
          <a href="#user">
            <Button color={Colors.green} arrow>
              users
              <Arrow />
            </Button>
          </a>
          <a href="#creators">
            <Button color={Colors.yellow} arrow>
              creators
              <Arrow />
            </Button>
          </a>
        </Buttons>
        <Label>( * = Multiple simulations supported)</Label>
      </TextWrapper>
      <Shoe
        ref={shoe}
        onMouseEnter={() => {
          shoeAnimation()
        }}
      >
        <Img fluid={data.shoe.childImageSharp.fluid} />
      </Shoe>
      <Card
        ref={card}
        onMouseEnter={() => {
          cardAnimation()
        }}
      >
        <Img fluid={data.heroTicket.childImageSharp.fluid} />
      </Card>
      <Astronaut ref={astro} onMouseEnter={astroAnimation}>
        <Img fluid={data.astronaut.childImageSharp.fluid} />
      </Astronaut>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 73.3vw;
  padding: 18.7vw 6.94%;
  color: white;
  overflow: hidden;
  position: relative;
  max-width: 100%;
  ${media.tablet} {
    height: 238.2vw;
    padding: 108.2vw 4.8vw;
  }
`
const TextWrapper = styled.div`
  position: relative;
  opacity: 0;
  transform: translate3d(0, 200px, 0);
  z-index: 3;
  width: 42.5vw;
  ${media.tablet} {
    width: 90.1vw;
  }
`
const Title = styled(Title1)`
  width: 42.6vw;
  color: white;
  ${media.tablet} {
    width: 98%;
  }
`
const Paragraph = styled(Paragraph1)`
  width: 33.8vw;
  margin-top: 2vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 7.2vw;
  }
`
const Buttons = styled.div`
  width: 27.9vw;
  display: flex;
  justify-content: space-between;
  margin-top: 4.5vw;
  ${media.tablet} {
    width: 90.1vw;
    margin-top: 10.9vw;
  }
`
const Button = styled(BoxOutlineButton)`
  width: 12.9vw;
  ${media.tablet} {
    width: 42.3vw;
  }
`
const Arrow = styled(ArrowSVG)`
  width: 1.7vw;
  ${media.tablet} {
    width: 6vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.brightRed};
  margin-top: 10.8vw;
`
const Grid = styled(GridSVG)`
  position: absolute;
  left: 19vw;
  top: -6.9vw;
  width: 114.4vw;
  transform: rotate(8.25deg);
  opacity: 0;
  ${media.tablet} {
    left: 0vw;
    top: 0vw;
    width: 175vw;
  }
`
const Shoe = styled.div`
  position: absolute;
  width: 60vw;
  top: 0.8vw;
  left: 37.6vw;
  filter: grayscale(1);
  opacity: 0.8;
  transform: translate3d(500px, 500px, 0) scale(0.5) rotate(-35.02deg);
  ${media.tablet} {
    width: 119vw;
    left: -13vw;
    top: -3.8vw;
  }
`
const Card = styled.div`
  position: absolute;
  width: 18.3vw;
  top: 6.4vw;
  left: 43.4vw;
  filter: grayscale(1);
  transform: translate3d(500px, 500px, 0) scale(0.5) rotate(-35.02deg);
  ${media.tablet} {
    left: 3.1vw;
    top: 21vw;
    width: 30vw;
    transform: translate3d(500px, 500px, 0) scale(0.5) rotate(35.02deg);
  }
`
const Astronaut = styled.div`
  width: 41.6vw;
  position: absolute;
  top: 19.3vw;
  left: 74.1vw;
  transform: rotate(-7.31deg);
  filter: grayscale(1);
  opacity: 0.8;
  transform: translate3d(500px, 500px, 0) scale(0.5) rotate(-35.02deg);
  ${media.tablet} {
    width: 65vw;
    left: 49vw;
    top: 68.4vw;
  }
`
export default Hero
