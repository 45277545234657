import React from "react"
import { Paragraph3, Title3 } from "src/components/Text"
import Colors from "src/styles/Colors"
import styled from "styled-components"

import { ReactComponent as TextSVG } from "svg/youPlusBitski.svg"
import { ReactComponent as TextMobileSVG } from "svg/youPlusBitskiMobile.svg"

import { ReactComponent as SmileSVG } from "svg/smile.svg"
import media from "src/styles/media"

const YouPlusBitski: React.FC<{}> = () => {
  return (
    <Wrapper>
      <Text />
      <TextMobile />
      <Smile />
      <ContentWrapper>
        <TextWrapper>
          <Title>UNLOCKING MORE POSSIBILITES</Title>
          <Paragraph>
            Bitksi is a growing product and with the power of blockchain we are
            figuring out more and more about what we are capable of.
            <br />
            <br />
            We hope you are inspired but the possibilites and hope to be
            inspired by your ideas.
            <br />
            <br />
            Reach out and let’t build the future together.
          </Paragraph>
        </TextWrapper>
        <Form>
          <Input name="name" placeholder="your name" />
          <Input name="email" type="email" placeholder="your@email.com" />
          <TextArea placeholder="your idea for bitski" name="idea" />
          <Submit type="submit" value="send it" />
        </Form>
      </ContentWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 49vw;
  position: relative;
  ${media.tablet} {
    height: min-content;
  }
`
const Text = styled(TextSVG)`
  width: 61.32%;
  margin-left: 19.38%;
  ${media.tablet} {
    display: none;
  }
`
const TextMobile = styled(TextMobileSVG)`
  display: none;
  ${media.tablet} {
    display: block;
    width: 90.3%;
    margin-left: 4.8vw;
  }
`
const ContentWrapper = styled.div`
  width: 61.81%;
  display: flex;
  justify-content: space-between;
  margin-left: 24.31%;
  margin-top: 5vw;
  ${media.tablet} {
    display: block;
    margin-left: 4.8vw;
    width: 90.3%;
  }
`
const TextWrapper = styled.div`
  width: 25.7vw;
  ${media.tablet} {
    width: 77.5vw;
    margin-top: 13.3vw;
  }
`
const Title = styled(Title3)`
  color: ${Colors.purple};
  width: 20.5vw;
  font-family: "Neue Machina";

  font-weight: normal;
  margin-bottom: 0.7vw;
  ${media.tablet} {
    width: 71.3vw;
    margin-bottom: 3.6vw;
    font-size: 5.8vw;
  }
`
const Paragraph = styled(Paragraph3)`
  color: white;
  padding-left: 3.5vw;
  ${media.tablet} {
    margin-left: 9.6vw;
  }
`
const Form = styled.form`
  width: 29.8vw;
  ${media.tablet} {
    display: block;
    width: 90.3vw;
  }
`
const Input = styled.input`
  width: 100%;
  border: none;
  height: 3.5vw;
  background: #2f2f33;
  color: white;
  margin-bottom: 1.4vw;
  padding: 0 1.7vw;
  font-size: 1.2vw;
  ::placeholder {
    color: #464646;
  }
  ${media.tablet} {
    height: 12.1vw;
    margin-top: 4.8vw;
    font-size: 4.3vw;
    padding: 0 6vw;
    border-radius: 0;
  }
`
const TextArea = styled.textarea`
  width: 100%;
  border: none;
  height: 10vw;
  background: #2f2f33;
  color: white;
  margin-bottom: 1.4vw;
  padding: 1.1vw 1.7vw;
  font-size: 1.2vw;
  ::placeholder {
    color: #464646;
  }
  resize: none;
  ${media.tablet} {
    height: 44.2vw;
    margin-top: 4.8vw;
    font-size: 4.3vw;
    padding: 3.9vw 6vw;
    border-radius: 0;
  }
`
const Submit = styled.input`
  width: 100%;
  border: solid 1px ${Colors.purple};
  color: ${Colors.purple};
  height: 3.5vw;
  background: transparent;
  max-width: 100%;
  :hover {
    background: ${Colors.purple};
    color: white;
    border: solid 1px ${Colors.offWhite};
  }
  ${media.tablet} {
    height: 18.1vw;
    margin-top: 7.2vw;
    margin-bottom: 15vw;
    font-size: 4.3vw;
    border-radius: 0;
  }
`
const Smile = styled(SmileSVG)`
  width: 11.46%;
  position: absolute;
  top: 17.3vw;
  left: 11.11%;
  ${media.tablet} {
    width: 24.9vw;
    top: 44.4vw;
    left: 62.1vw;
  }
`
export default YouPlusBitski
