import React, { useState } from "react"
import styled from "styled-components"

import { Paragraph3, Title2, Title3 } from "src/components/Text"
import Colors from "src/styles/Colors"

import pointer from "svg/pointer.svg"
import media from "src/styles/media"

const Faq: React.FC<{}> = () => {
  const [oneActive, setOneActive] = useState(false)
  const [twoActive, setTwoActive] = useState(false)
  const [threeActive, setThreeActive] = useState(false)
  const [fourActive, setFourActive] = useState(false)

  return (
    <Wrapper>
      <Title>Frequently Asked Questions</Title>
      <QuestionWrapper>
        <Question
          active={oneActive}
          aria-label="toggle answer"
          onClick={() => setOneActive(!oneActive)}
        >
          <QuestionTitle>What’s an NFT?</QuestionTitle>
          <Answer active={oneActive}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Answer>
          <Toggle>
            <Plus active={oneActive} />
          </Toggle>
        </Question>
        <Question
          active={twoActive}
          aria-label="toggle answer"
          onClick={() => setTwoActive(!twoActive)}
        >
          <QuestionTitle>Why is this important?</QuestionTitle>
          <Answer active={twoActive}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Answer>
          <Toggle>
            <Plus active={twoActive} />
          </Toggle>
        </Question>
        <Question
          active={threeActive}
          aria-label="toggle answer"
          onClick={() => setThreeActive(!threeActive)}
        >
          <QuestionTitle>what’s a community token?</QuestionTitle>
          <Answer active={threeActive}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Answer>
          <Toggle>
            <Plus active={threeActive} />
          </Toggle>
        </Question>
        <Question
          active={fourActive}
          aria-label="toggle answer"
          onClick={() => setFourActive(!fourActive)}
        >
          <QuestionTitle>I work at an agency. Can you help me?</QuestionTitle>
          <Answer active={fourActive}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </Answer>
          <Toggle
            aria-label="toggle answer"
            onClick={() => setFourActive(!fourActive)}
          >
            <Plus active={fourActive} />
          </Toggle>
        </Question>
      </QuestionWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 67.1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 8.3vw;
  ${media.tablet} {
    height: min-content;
    min-height: 194vw;
    padding: 18.1vw 4.8vw 0;
    transition: 200ms ease-out;
  }
`
const Title = styled(Title2)`
  color: white;
`
const QuestionWrapper = styled.div`
  width: 72.22%;
  margin-top: 4.6vw;
  ${media.tablet} {
    width: 100%;
    margin-top: 18.1vw;
  }
`
const Question = styled.button<{ active: boolean }>`
  background: transparent;
  border: none;
  text-align: left;
  width: 100%;
  transition: 200ms ease-out;
  min-height: 6.8vw;
  border-top: 1px solid rgb(255, 53, 104, 0.25);
  padding: 2.4vw 0;
  position: relative;
  &:nth-child(1) {
    border-top: none;
  }
  ${media.tablet} {
    min-height: 20vw;
    padding: 7.2vw 0;
  }
`
const QuestionTitle = styled(Title3)`
  font-weight: normal;
  font-family: "Neue Machina";
  color: white;
  text-transform: uppercase;
  ${media.tablet} {
    font-size: 5.8vw;
    width: 76vw;
    line-height: 100%;
  }
`
const Answer = styled(Paragraph3)<{ active: boolean }>`
  margin-left: 2.4vw;
  margin-top: ${props => (props.active ? "1.8vw" : "0")};
  width: 44.4vw;
  overflow: hidden;
  transition: 200ms ease-out;
  height: ${props => (props.active ? "min-content" : "0")};
  color: white;
  ${media.tablet} {
    width: 60vw;
    margin-left: 5.8vw;
    margin-top: ${props => (props.active ? "4.8vw" : "0")};
  }
`
const Toggle = styled.div`
  background: transparent;
  border: none;
  width: 1.4vw;
  height: 1.4vw;
  position: absolute;
  right: 0;
  padding: 0;
  top: 2.7vw;
  cursor: url(${pointer}), auto;
`
const Plus = styled.div<{ active: boolean }>`
  width: 20px;
  height: 4px;
  background: ${Colors.brightRed};
  transform: rotate(${props => (props.active ? "0deg" : "90deg")});
  transition: 200ms ease-out;
  ::after {
    width: 20px;
    height: 4px;
    background: ${Colors.brightRed};
    transform: rotate(${props => (props.active ? "0deg" : "90deg")});
    transition: 200ms ease-out;
    content: " ";
    position: absolute;
    right: 0vw;
  }
  ${media.tablet} {
    position: absolute;
    top: 7.5vw;
    right: 2.1vw;
  }
`

export default Faq
