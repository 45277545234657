import React, { useRef, useEffect } from "react"
import { Label1 } from "src/components/Text"
import Colors from "src/styles/Colors"
import gsap from "gsap"

import { FluidImage } from "src/Types"

import styled from "styled-components"
import Section1 from "./creatorToolsSections/Section1"
import Section2 from "./creatorToolsSections/Section2"
import Section3 from "./creatorToolsSections/Section3"
import media from "src/styles/media"

import mouse from "svg/darkCursor.svg"
import pointer from "svg/darkPointer.svg"

type Props = {
  data: {
    skins: FluidImage
    sneakerDrop: FluidImage
    goldenTicket: FluidImage
    tokenBadge: FluidImage
    goodIdeas: FluidImage
    astro1: FluidImage
    lineGrid: FluidImage
    builtOnBlockchain: FluidImage
    yourPlatform: FluidImage
  }
}

const OneSVG = () => {
  return (
    <svg viewBox="0 0 252 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="tools__one"
        d="M66.9467 117.62C111.821 117.62 133.367 96.722 133.367 59.138C133.367 21.716 111.821 0.979994 66.9467 0.979994C21.9107 0.979994 0.526664 21.554 0.526664 59.138C0.526664 96.884 21.9107 117.62 66.9467 117.62ZM66.9467 93.158C41.3507 93.158 29.6867 80.522 29.6867 59.138C29.6867 37.916 41.3507 25.442 66.9467 25.442C92.5427 25.442 104.207 38.078 104.207 59.138C104.207 80.36 92.5427 93.158 66.9467 93.158ZM212.958 91.538V2.59999H183.798C183.798 24.308 161.604 24.308 144.918 24.308V48.932C156.744 48.932 171.81 48.932 184.122 38.078L183.96 91.538H144.918V116H251.838V91.538H212.958Z"
        fill="rgba(255,203,69,0)"
        stroke={Colors.yellow}
      />
    </svg>
  )
}
const TwoSVG = () => {
  return (
    <svg viewBox="0 0 269 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="tools__two"
        d="M67.0902 117.62C111.964 117.62 133.51 96.722 133.51 59.138C133.51 21.716 111.964 0.979994 67.0902 0.979994C22.0542 0.979994 0.670219 21.554 0.670219 59.138C0.670219 96.884 22.0542 117.62 67.0902 117.62ZM67.0902 93.158C41.4942 93.158 29.8302 80.522 29.8302 59.138C29.8302 37.916 41.4942 25.442 67.0902 25.442C92.6862 25.442 104.35 38.078 104.35 59.138C104.35 80.36 92.6862 93.158 67.0902 93.158ZM268.273 36.62C268.273 8.43199 245.431 0.979994 206.389 0.979994C168.805 0.979994 142.237 9.40399 142.237 47.15H171.397C171.397 29.168 182.899 25.442 208.009 25.442C233.119 25.442 239.113 29.492 239.113 38.888C239.113 64.646 139.645 54.116 139.645 101.258V116H266.653V91.538H180.469C187.597 69.02 268.273 80.522 268.273 36.62Z"
        fill="rgba(255,203,69,0)"
        stroke={Colors.yellow}
      />
    </svg>
  )
}
const ThreeSVG = () => {
  return (
    <svg viewBox="0 0 272 118" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="tools__three"
        d="M67.1664 117.62C112.04 117.62 133.586 96.722 133.586 59.138C133.586 21.716 112.04 0.979994 67.1664 0.979994C22.1304 0.979994 0.746391 21.554 0.746391 59.138C0.746391 96.884 22.1304 117.62 67.1664 117.62ZM67.1664 93.158C41.5704 93.158 29.9064 80.522 29.9064 59.138C29.9064 37.916 41.5704 25.442 67.1664 25.442C92.7624 25.442 104.426 38.078 104.426 59.138C104.426 80.36 92.7624 93.158 67.1664 93.158ZM253.701 59.3C264.555 54.116 271.197 46.34 271.197 34.514C271.197 9.40399 248.355 0.979994 208.503 0.979994C169.137 0.979994 142.569 9.40399 142.569 45.53H171.729C171.729 28.358 182.421 25.442 210.123 25.442C235.233 25.442 242.037 28.358 242.037 38.24C242.037 49.58 232.479 50.552 193.923 50.552V68.048C233.289 68.048 242.037 69.02 242.037 80.36C242.037 91.214 233.289 93.158 210.123 93.158C185.175 93.158 171.729 91.214 171.729 73.07H142.569C142.569 109.196 169.137 117.62 208.503 117.62C248.355 117.62 271.197 109.196 271.197 84.086C271.197 72.26 264.555 64.484 253.701 59.3Z"
        fill="rgba(255,203,69,0)"
        stroke={Colors.yellow}
      />
    </svg>
  )
}

const CreatorTools: React.FC<Props> = ({ data }) => {
  const section1: any = useRef()
  const section2: any = useRef()
  const section3: any = useRef()
  const one: any = useRef()
  const two: any = useRef()
  const three: any = useRef()

  useEffect(() => {
    gsap.to(".tools__one", {
      scrollTrigger: {
        trigger: section1.current,
        start: "-8%",
        toggleActions: "play reset play reset",
      },
      fill: Colors.yellow,
      duration: 0.8,
      ease: "power4.out",
    })

    gsap.to(".tools__two", {
      scrollTrigger: {
        trigger: section2.current,
        start: "-25%",
        toggleActions: "play reset play reset",
      },
      fill: Colors.yellow,
      duration: 0.8,
      ease: "power4.out",
    })
    gsap.to(".tools__three", {
      scrollTrigger: {
        trigger: section3.current,
        start: "-25%",
        toggleActions: "play reset play reset",
      },
      fill: Colors.yellow,
      duration: 0.8,
      ease: "power4.out",
    })
  }, [])
  return (
    <Wrapper id="#creators">
      <Top>
        <LabelWrapper>
          <Label>creator tools</Label>
        </LabelWrapper>
        <PageNumber>/03</PageNumber>
      </Top>
      <Section ref={section1}>
        <Number ref={one}>
          <OneSVG />
        </Number>
        <Section1 data={data} />
      </Section>
      <Section ref={section2}>
        <Number ref={two}>
          <TwoSVG />
        </Number>
        <Section2 data={data} />
      </Section>
      <Section ref={section3}>
        <Number ref={three}>
          <ThreeSVG />
        </Number>
        <Section3 data={data} />
      </Section>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 249.2vw;
  background: ${Colors.offWhite};
  ${media.tablet} {
    height: 1253.6vw;
  }
  cursor: url(${mouse}), auto;
  button {
    cursor: url(${pointer}), auto;
  }
`
const Top = styled.div`
  height: 10.8vw;
  padding: 2.7vw 6.9vw;
  display: flex;
  justify-content: space-between;
  position: sticky;
  background: linear-gradient(
    180deg,
    #f4f4f4 66.36%,
    rgba(244, 244, 244, 0) 80%
  );
  top: 0;
  z-index: 5;
  ${media.tablet} {
    height: 31.4vw;
    padding: 10.8vw 4.8vw;
  }
`
const Section = styled.div`
  display: flex;
  align-items: flex-start;
  ${media.tablet} {
    position: relative;
  }
`

const LabelWrapper = styled.div`
  width: 11.6vw;
  height: 3.2vw;
  border: solid 1px ${Colors.yellow};
  border-radius: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    height: 8vw;
    width: 32.1vw;
    border-radius: 24.1vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.yellow};
  font-size: 2vw;
  line-height: 1.0666666666666667em;
  letter-spacing: -0.043125em;
  text-align: center;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`
const PageNumber = styled(Label)`
  margin-top: 0.7vw;
`

const Number = styled.div`
  width: 18.33%;
  margin-left: 6.9vw;
  position: sticky;
  top: 8vw;
  ${media.tablet} {
    width: 39.3vw;
    position: absolute;
    top: 0;
    margin-left: 4.8vw;
  }
`

export default CreatorTools
