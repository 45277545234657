import React, { useEffect, useRef } from "react"
import Img from "gatsby-image"
import styled from "styled-components"
import gsap from "gsap"

import { Label1, Paragraph1, Title1, Title3 } from "src/components/Text"
import Colors from "src/styles/Colors"

import { FluidImage } from "src/Types"

import { BoxOutlineButton } from "src/components/Buttons"
import { ReactComponent as ArrowSVG } from "svg/arrowRight.svg"
import media from "src/styles/media"

type Props = {
  data: {
    skins: FluidImage
    sneakerDrop: FluidImage
    goldenTicket: FluidImage
    tokenBadge: FluidImage
    goodIdeas: FluidImage
  }
}

const Section1: React.FC<Props> = ({ data }) => {
  const title: any = useRef()
  const ideas: any = useRef()
  useEffect(() => {
    const tl1 = gsap.timeline({
      scrollTrigger: {
        trigger: title.current,
        start: "center center",
      },
    })
    tl1
      .to(".creators__section1-title", {
        opacity: 1,
        y: 0,
        ease: "power4.out",
        duration: 0.5,
        stagger: 0.1,
      })
      .to(
        ".creators__section1-detail",
        {
          opacity: 1,
          y: 0,
          scaleY: 1,
          ease: "power4.out",
          duration: 0.25,
          stagger: 0.2,
        },
        0.5
      )
    const tl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ideas.current,
        start: "top center",
      },
    })
    tl2
      .to(".creators__section1-ideas", { opacity: 1 })
      .to(
        ".creators__section1-image, .creators__section1-imageDesc",
        { opacity: 1, stagger: 0.1 },
        0.3
      )
      .to(
        ".creators__section1-ideasBadge",
        { opacity: 1, scale: 1, ease: "back.out(1.7)" },
        0.7
      )
  }, [])
  return (
    <Wrapper>
      <Title ref={title} className="creators__section1-title">
        NFT Drops
      </Title>
      <Label className="creators__section1-title">
        sell unique, ownable digital goods
      </Label>
      <Line className="creators__section1-title" />
      <Details>
        <Detail className="creators__section1-detail">
          <TitleSecondary>What's an NFT?</TitleSecondary>
          <Paragraph>
            An NFT is a digital good that can be owned. and sold. and traded.
            and used in virtual worlds.
          </Paragraph>
        </Detail>
        <Detail className="creators__section1-detail">
          <TitleSecondary>Sell NFTs</TitleSecondary>
          <Paragraph>
            Sell 'em, Auction 'em, give 'em away – you do that. Easily manage
            and sell NFT's to a mainstream audience. Blockchain experience 100%
            not required.
          </Paragraph>
        </Detail>
        <Detail className="creators__section1-detail">
          <TitleSecondary>Skins &amp; Lens</TitleSecondary>
          <Paragraph>
            NFTs can act like keys to unlock rare content. Pair a Snapchat
            filter or Roblox skin with your NFT and let users represent
            themselves in their simulation of choice.
          </Paragraph>
        </Detail>
        <Detail className="creators__section1-detail">
          <TitleSecondary>Fashion, but make it digital</TitleSecondary>
          <Paragraph>
            Pair digital version with your physical good drops. Stripe and
            Shopify integration supported.
          </Paragraph>
        </Detail>
      </Details>
      <DropIdeas ref={ideas} className="creators__section1-ideas">
        Need drop ideas? We got em.
      </DropIdeas>
      <Ideas className="creators__section1-ideasBadge">
        <Img fluid={data.goodIdeas.childImageSharp.fluid} />
      </Ideas>
      <ImageWrapper>
        <Skins className="creators__section1-image">
          <Img fluid={data.skins.childImageSharp.fluid} />
        </Skins>
        <Sneaker className="creators__section1-image">
          <Img fluid={data.sneakerDrop.childImageSharp.fluid} />
        </Sneaker>
        <Ticket className="creators__section1-image">
          <Img fluid={data.goldenTicket.childImageSharp.fluid} />
        </Ticket>
        <Token className="creators__section1-image">
          <Img fluid={data.tokenBadge.childImageSharp.fluid} />
        </Token>
      </ImageWrapper>
      <TextWrapper>
        <ImageDescription className="creators__section1-imageDesc">
          <Label className="creators__section1-title">drop skins</Label>
          <Paragraph>
            Own very own custom skins to wear across multiple games.
          </Paragraph>
        </ImageDescription>
        <ImageDescription className="creators__section1-imageDesc">
          <Label className="creators__section1-title">sneaker drop</Label>
          <Paragraph>
            A sneaker drop that includes a virtual version that can be worn in
            games.
          </Paragraph>
        </ImageDescription>
        <ImageDescription className="creators__section1-imageDesc">
          <Label className="creators__section1-title">golden ticket</Label>
          <Paragraph>
            A golden ticket that unlocks early access to merch drops.
          </Paragraph>
        </ImageDescription>
        <ImageDescription className="creators__section1-imageDesc">
          <Label className="creators__section1-title">token / badge</Label>
          <Paragraph>
            A token or badge for large donation to a streamer.
          </Paragraph>
        </ImageDescription>
      </TextWrapper>
      <Button color="black" arrow>
        drop my first nft!
        <Arrow />
      </Button>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 95.5vw;
  width: 72.22vw;
  padding: 1.3vw 0 0 0;
  margin-left: 2.5vw;
  position: relative;
  // overflow: hidden;
  ${media.tablet} {
    margin-left: 4.8vw;
    width: 90.3vw;
    height: 517.1vw;
  }
`
const Title = styled(Title1)`
  color: ${Colors.darkGrey};
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  ${media.tablet} {
    margin-left: 25.4vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.darkGrey};
  line-height: 1.08em;
  letter-spacing: -0.02875em;
  font-size: 1.7vw;
  opacity: 0;
  transform: translate3d(0, 100px, 0);
  ${media.tablet} {
    margin-left: 25.4vw;
    font-size: 4.8vw;
  }
`
const Line = styled.hr`
  width: 65.3vw;
  height: 2px;
  background: ${Colors.darkGrey};
  margin: 1.8vw 0 5.2vw;
  opacity: 0;
  ${media.tablet} {
    width: 100%;
    height: 1px;
    margin: 7.2vw 0 18.4vw;
  }
`
const Details = styled.div`
  width: 65.3vw;
  display: grid;
  grid-template-columns: repeat(2, 29.3vw);
  grid-column-gap: 6.5vw;
  ${media.tablet} {
    width: 100%;
    display: block;
  }
`
const Detail = styled.div`
  height: 13.8vw;
  transform: translate3d(0, 50px, 0) scaleY(1.2);
  opacity: 0;
  ${media.tablet} {
    height: unset;
    margin-bottom: 12.1vw;
  }
`
const TitleSecondary = styled(Title3)`
  font-family: "Neue Machina";
  font-weight: normal;
  ${media.tablet} {
    width: 56.3vw;
  }
`
const Paragraph = styled(Paragraph1)`
  margin-left: 3.3vw;
  color: ${Colors.charcoal};
  margin-top: 0.7vw;
  ${media.tablet} {
    margin-left: 12.3vw;
    margin-top: 2.4vw;
  }
`
const DropIdeas = styled(Title3)`
  margin-top: 5.2vw;
  font-weight: normal;
`
const ImageWrapper = styled.div`
  position: relative;
`
const Skins = styled.div`
  width: 24.9vw;
  position: absolute;
  left: -11.2vw;
  top: -0.5vw;
  ${media.tablet} {
    width: 74vw;
    left: -30vw;
    top: 15.7vw;
  }
`
const Sneaker = styled.div`
    position: absolute;
    width 19.7vw;
    left: 10.7vw;
    top: -0.4vw;
    ${media.tablet} {
      width: 65.9vw;
      left: 29.2vw;
      top: 62.8vw;
    }

`

const Ticket = styled.div`
  position: absolute;
  width: 23.7vw;
  left: 28.3vw;
  top: -1.8vw;
  ${media.tablet} {
    width: 63vw;
    left: -11.1vw;
    top: 113.8vw;
  }
`
const Token = styled.div`
  position: absolute;
  width: 18.6vw;
  left: 46.7vw;
  top: -0.2vw;
  ${media.tablet} {
    width: 64.7vw;
    left: 29.9vw;
    top: 165.5vw;
  }
`
const Ideas = styled.div`
  width: 11.5vw;
  position: absolute;
  left: 38vw;
  top: 42.3vw;
  opacity: 0;
  transform: scale(0.2);
  ${media.tablet} {
    width: 31.4vw;
    top: 248.5vw;
    left: 58.9vw;
  }
`
const TextWrapper = styled.div`
  width: 67vw;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 14.9vw;
  p {
    margin-left: 0;
  }
  ${media.tablet} {
    display: block;
    margin-top: 34vw;
  }
`
const ImageDescription = styled.div`
  width: 13.4vw;
  ${media.tablet} {
    width: 46vw;
    margin-bottom: 26.5vw;
    &:nth-child(odd) {
      margin-left: 43.2vw;
    }
  }
`

const Button = styled(BoxOutlineButton)`
  width: 16vw;
  background: ${Colors.yellow};
  margin-top: 4.2vw;
  ${media.tablet} {
    display: none;
  }
`
const Arrow = styled(ArrowSVG)`
  width: 1.7vw;
`
export default Section1
