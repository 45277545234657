import React from "react"

import HorizontalScroller from "components/HorizontalScroller"
import forUsersImage from "svg/forUsers.svg"
import Colors from "src/styles/Colors"

const ForUsers: React.FC<{}> = () => {
  return <HorizontalScroller image={forUsersImage} border={Colors.green} />
}

export default ForUsers
