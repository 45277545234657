import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

import { Label1, Paragraph3, Title3 } from "src/components/Text"
import { BoxOutlineButton } from "src/components/Buttons"

import Colors from "src/styles/Colors"

import { FluidImage } from "src/Types"
import pointer from "svg/pointer.svg"
import media from "src/styles/media"

type Props = {
  data: {
    banksy: FluidImage
  }
}

const UseCases: React.FC<Props> = ({ data }) => {
  const ref: any = useRef()
  const firstAnimationComplete = useRef(false)
  const step = useRef(0)
  const isAnimating = useRef(false)
  const interval = useRef(0)

  const initialAnimation = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
        interval.current = setInterval(cycle, 3000)
      },
    })
    tl.to(".use__step1-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2-text, .use__step3-text, .use__step4-text, .use__step5-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step1", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step4, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator1",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator3, .use__indicator4, .use__indicator5, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
    tl.to(".use__step", { opacity: 1, x: 0, duration: 0.3, stagger: 0.1 }, 0)
    tl.to(".use__step1", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step4, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
  }

  const cycle = () => {
    switch (step.current) {
      case 0:
        step1()
        step.current = 1
        break
      case 1:
        step2()
        step.current = 2
        break
      case 2:
        step3()
        step.current = 3
        break
      case 3:
        step4()
        step.current = 4
        break
      case 4:
        step5()
        step.current = 5
        break
      case 5:
        step0()
        step.current = 0
      default:
        break
    }
  }

  const step0 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step1-text", { color: "white", duration: 0.3 }, 0)

    tl.to(
      ".use__step2-text, .use__step3-text, .use__step4-text, .use__step5-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step1", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step4, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator1",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator3, .use__indicator4, .use__indicator5, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  const step1 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step2-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step1-text, .use__step3-text, .use__step4-text, .use__step5-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step2", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step1, .use__step3, .use__step4, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator1, .use__indicator3, .use__indicator4, .use__indicator5, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  const step2 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step3-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2-text, .use__step1-text, .use__step4-text, .use__step5-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step3", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step2, .use__step4, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator3",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator1, .use__indicator4, .use__indicator5, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  const step3 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step4-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2-text, .use__step3-text, .use__step1-text, .use__step5-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step4", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step1, .use__step5, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator4",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator3, .use__indicator1, .use__indicator5, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  const step4 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step5-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2-text, .use__step3-text, .use__step4-text, .use__step1-text, .use__step6-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step5", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step4, .use__step1, .use__step6",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator5",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator3, .use__indicator4, .use__indicator1, .use__indicator6",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  const step5 = () => {
    isAnimating.current = true
    const tl = gsap.timeline({
      onComplete: () => {
        isAnimating.current = false
      },
    })
    tl.to(".use__step6-text", { color: "white", duration: 0.3 }, 0)
    tl.to(
      ".use__step2-text, .use__step3-text, .use__step4-text, .use__step5-text, .use__step1-text",
      { color: "#2F2F33", duration: 0.3 },
      0
    )
    tl.to(".use__step6", { opacity: 1, duration: 0.3 }, 0)
    tl.to(
      ".use__step2, .use__step3, .use__step4, .use__step5, .use__step1",
      { opacity: 0, duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator6",
      { width: "1.7vw", marginRight: "1vw", duration: 0.3 },
      0
    )
    tl.to(
      ".use__indicator2, .use__indicator3, .use__indicator4, .use__indicator5, .use__indicator1",
      { width: 0, marginRight: 0, duration: 0.3 },
      0
    )
  }
  useEffect(() => {
    ScrollTrigger.create({
      trigger: ref.current,
      start: "top 20%",
      onToggle: ({}) => {
        if (firstAnimationComplete.current) {
          return
        }
        initialAnimation()
        firstAnimationComplete.current = true
      },
    })
    return () => {
      clearInterval(interval.current)
    }
  }, [])

  return (
    <Wrapper ref={ref}>
      <LabelWrapper>
        <Label>use cases</Label>
      </LabelWrapper>
      <PageNumber>(06)</PageNumber>
      <StepList>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step0()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator1" />{" "}
            <StepText className="use__step1-text">Banksy art nft</StepText>
          </StepButton>
        </Step>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step1()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator2" />{" "}
            <StepText className="use__step2-text">
              Dr. Disrespect Token
            </StepText>
          </StepButton>
        </Step>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step2()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator3" />{" "}
            <StepText className="use__step3-text">Nike X APEX LEGENDS</StepText>
          </StepButton>
        </Step>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step3()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator4" />{" "}
            <StepText className="use__step4-text">TOKEN TRADING</StepText>
          </StepButton>
        </Step>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step4()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator5" />{" "}
            <StepText className="use__step5-text">CoD Warzone badge</StepText>
          </StepButton>
        </Step>
        <Step className="use__step">
          <StepButton
            onClick={() => {
              step5()
              clearInterval(interval.current)
            }}
          >
            <Indicator className="use__indicator6" />{" "}
            <StepText className="use__step6-text">A.I. SCulpted shoe</StepText>
          </StepButton>
        </Step>
      </StepList>
      <Divide />

      {/* Step Content wrapping them in fragments so it is easier to see the different steps*/}
      <>
        <Image className="use__step1">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step1">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step1">
          go to demo
        </Button>
      </>
      <>
        <Image className="use__step2">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step2">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step2">
          go to demo
        </Button>
      </>

      <>
        <Image className="use__step3">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step3">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step3">
          go to demo
        </Button>
      </>

      <>
        <Image className="use__step4">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step4">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step4">
          go to demo
        </Button>
      </>

      <>
        <Image className="use__step5">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step5">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step5">
          go to demo
        </Button>
      </>

      <>
        <Image className="use__step6">
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </Image>
        <TextWrapper className="use__step6">
          <Title>A LIVE NFT AUCTION DEMO</Title>
          <Paragraph>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamc.
            <br />
            <br />
            Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
            veniam, quis nostrud exercitation ullamc sed do eiusmod sed do
            eiusmod
            <br />
            <br />
            Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
            eiusmod sed do eiusmod{" "}
          </Paragraph>
        </TextWrapper>
        <Button color={Colors.purple} className="use__step6">
          go to demo
        </Button>
      </>

      <MobileStep>
        <MobileStepTitle>Banksy art nft</MobileStepTitle>
        <MobileLabel>01</MobileLabel>
        <MobileImage>
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </MobileImage>
        <MobileButton color={Colors.purple}>go to demo</MobileButton>
        <Title>A LIVE NFT AUCTION DEMO</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamc.
          <br />
          <br />
          Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamc sed do eiusmod sed do eiusmod
          <br />
          <br />
          Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
          eiusmod sed do eiusmod{" "}
        </Paragraph>
      </MobileStep>
      <MobileStep>
        <MobileStepTitle>Dr. Disrespect Token</MobileStepTitle>
        <MobileLabel>02</MobileLabel>

        <MobileImage>
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </MobileImage>
        <MobileButton color={Colors.purple}>go to demo</MobileButton>
        <Title>A LIVE NFT AUCTION DEMO</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamc.
          <br />
          <br />
          Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamc sed do eiusmod sed do eiusmod
          <br />
          <br />
          Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
          eiusmod sed do eiusmod{" "}
        </Paragraph>
      </MobileStep>
      <MobileStep>
        <MobileStepTitle>Truman HALLADAY Art drop</MobileStepTitle>
        <MobileLabel>03</MobileLabel>

        <MobileImage>
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </MobileImage>
        <MobileButton color={Colors.purple}>go to demo</MobileButton>
        <Title>A LIVE NFT AUCTION DEMO</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamc.
          <br />
          <br />
          Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamc sed do eiusmod sed do eiusmod
          <br />
          <br />
          Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
          eiusmod sed do eiusmod{" "}
        </Paragraph>
      </MobileStep>
      <MobileStep>
        <MobileStepTitle>Nike X APEX LEGENDS</MobileStepTitle>
        <MobileLabel>04</MobileLabel>

        <MobileImage>
          <Img fluid={data.banksy.childImageSharp.fluid} />
        </MobileImage>
        <MobileButton color={Colors.purple}>go to demo</MobileButton>
        <Title>A LIVE NFT AUCTION DEMO</Title>
        <Paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamc.
          <br />
          <br />
          Tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
          veniam, quis nostrud exercitation ullamc sed do eiusmod sed do eiusmod
          <br />
          <br />
          Ut enim ad minim veniam, quis nostrud exercitation ullamc sed do
          eiusmod sed do eiusmod{" "}
        </Paragraph>
      </MobileStep>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  height: 54.3vw;
  padding: 2.7vw 6.94vw;
  position: relative;
  width: 100vw;
  overflow: hidden;
  max-width: 100%;
  ${media.tablet} {
    height: min-content;
    padding: 10.9vw 4.8vw;
  }
`
const LabelWrapper = styled.div`
  width: 9.9vw;
  height: 3.2vw;
  border: solid 1px ${Colors.purple};
  border-radius: 3.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.tablet} {
    height: 8vw;
    width: 32.1vw;
    border-radius: 24.1vw;
    margin-bottom: 18.6vw;
  }
`
const Label = styled(Label1)`
  color: ${Colors.purple};
  font-size: 2vw;
  line-height: 1.0666666666666667em;
  letter-spacing: -0.043125em;
  text-align: center;
  ${media.tablet} {
    font-size: 4.8vw;
  }
`
const PageNumber = styled(Label)`
  position: absolute;
  top: 3.5vw;
  right: 6.94vw;
  ${media.tablet} {
    top: 10.9vw;
  }
`
const StepList = styled.ul`
  width: max-content;
  margin-top: 5.4vw;
  ${media.tablet} {
    display: none;
  }
`
const Step = styled.li`
  height: 2.7vw;
  margin-bottom: 1.7vw;
  transform: translate3d(-100px, 0, 0);
  opacity: 0;
`
const StepButton = styled.button`
  background: transparent;
  border: none;
  cursor: url(${pointer}), auto;
  display: flex;
  align-items: center;
  padding: 0;
`
const StepText = styled.span`
  font-family: "Monument Extended";
  font-size: 1.7vw;
  line-height: 1.2em;
  letter-spacing: -0.02875em;
  color: #2f2f33;
  text-transform: uppercase;
`
const MobileStep = styled.div`
  display: none;
  ${media.tablet} {
    display: block;
    border-bottom: solid 1px ${Colors.purple};
    margin-bottom: 10.9vw;
    position: relative;
  }
`
const MobileLabel = styled(Label)`
  position: absolute;
  top: 1vw;
  right: 0;
  font-size: 6vw;
`
const MobileStepTitle = styled(Title3)`
  color: white;
  text-transform: uppercase;
  margin-bottom: 4.8vw;
  font-size: 6vw;
  width: 79vw;
`
const MobileImage = styled.div`
  width: 100%;
`
const MobileButton = styled(BoxOutlineButton)`
  width: 100%;
  height: 12vw;
  justify-content: center;
  margin-top: 4.8vw;
`
const Indicator = styled.div`
  width: 0;
  height: 3px;
  background: ${Colors.purple};
  margin-right: 1vw;
`
const Divide = styled.div`
  width: 1px;
  height: 37.8vw;
  background: ${Colors.purple};
  position: absolute;
  top: 10.6vw;
  left: 38.2vw;
  ${media.tablet} {
    display: none;
  }
`
const Image = styled.div`
  width: 22.2vw;
  position: absolute;
  top: 10.6vw;
  left: 41.7vw;
  opacity: 0;
  ${media.tablet} {
    display: none;
  }
`
const TextWrapper = styled.div`
  position: absolute;
  width: 24.4vw;
  top: 10.6vw;
  left: 68.6vw;
  opacity: 0;
  ${media.tablet} {
    display: none;
  }
`
const Title = styled(Title3)`
  color: ${Colors.purple};
  width: 17.7vw;
  font-family: "Neue Machina";

  font-weight: normal;
  margin-bottom: 0.7vw;
  ${media.tablet} {
    width: 100%;
    font-size: 5.8vw;
    margin-top: 9.7vw;
    margin-bottom: 2.4vw;
  }
`
const Paragraph = styled(Paragraph3)`
  color: white;
  padding-left: 3.5vw;
  ${media.tablet} {
    margin-bottom: 10.9vw;
  }
`
const Button = styled(BoxOutlineButton)`
  position: absolute;
  width: 22.2vw;
  justify-content: center;
  left: 41.7vw;
  top: 41.6vw;
  opacity: 0;
  :hover {
    border: solid 1px ${Colors.offWhite};
  }
  ${media.tablet} {
    display: none;
  }
`
export default UseCases
